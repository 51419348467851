/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"

function Footer() {
  return (
    <footer id="main-footer">
      Copyright &copy; {new Date().getFullYear()}
    </footer>
  )
}

export default Footer
