import React from "react"
import Layout from "../components/layout"
import Footer from "../components/footer"
import SEO from "../components/seo"

const CvPage = props => {
  return (
    <div className="body">
      <Layout link="cv">
        <SEO
          title="My cv"
          description="Hi, You can downlaod my cv and know more about my work"
          keywords="Cv, Resume,Nwachukwu chibuike, Chibuike, Nwachukwu, Web developer nigeria cv, VueJs cv, Lagos, FUTO"
        />
        <main id="about">
          <h1 className="lg-heading">
            My&nbsp;
            <span className="text-secondary">Cv</span>
          </h1>
          <h2 className="sm-heading">Get to know more about my journey.</h2>
          <div className="about-info">
            <div className="bio">
              <p>
                <a
                  href="https://chibuikenwa.com/cv.pdf"
                  target="_blank"
                  download
                >
                  <input
                    type="button"
                    class="download-cv"
                    value="Download my cv"
                  />
                </a>
              </p>
            </div>
          </div>
        </main>
      </Layout>
      <Footer />
    </div>
  )
}

export default CvPage
